import './App.css';

import Welcome from "./components/Welcome/Welcome";

function App() {
  return (
    <div>
      <Welcome />
    </div>
  );
}

export default App;
