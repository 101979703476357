import SocialTray from "../SocialTray/SocialTray";
import developer from './developer.svg';

import './welcome.css';

const Welcome = () => {

    return (
        <div className="container flex-column justfy-center align-center">
            <img className="illus" src={developer} alt="work in pregress" />
            <p className="heading margin-x-0 color-primary">
                Hi, I am <strong>Jaykumar Patil</strong> aka <strong>Jay</strong>.<br />
            </p>
            <p className="info color-secondary">
                Currently, I am working on a new design. In the meantime, 
                feel free to check me out on the following platforms.
            </p>
            <SocialTray />
        </div>
    )

}

export default Welcome;