
import socialMedias from "./socialMedias";

import '../../App.css';
import './socialTray.css';

const SocialTray = () => {

    return (
        <div className="social-media-tray flex-row">
            {
                socialMedias.map(({id, title, url, imagePath}) => (
                    <a href={url} key={id} target="_blank" rel="noreferrer">
                        <img src={imagePath} alt={title} />
                    </a>
                ))
            }
            
        </div>
    )

}

export default SocialTray;