import linkedin from './images/linkedin-64.png';
import github from './images/github-64.png';
import instagram from './images/instagram-64.png';

const socialMedias = [
    {
        id: 1,
        title: "linkedin",
        url: "https://www.linkedin.com/in/jaykumarpatil/",
        imagePath: linkedin
    },
    {
        id: 2,
        title: "github",
        url: "https://github.com/jkpatil211",
        imagePath: github
    },
    {
        id: 3,
        title: "instagram",
        url: "https://www.instagram.com/imjaykpatil/",
        imagePath: instagram
    }
]

export default socialMedias;